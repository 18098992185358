import { ElementToggle } from '../helpers/helper';
import RatesService from '../services/rates';
import eventBus from '../helpers/event-bus';
import exchangeFormEventNames from '../exchange-form/exchange-form-event-names';

import { HOME_PAGE_RATES_CAROUSEL_UPDATE } from '../home-page/home-page-event-names';

export const CryptoRatesData = new CryptoRates();

/*
 ==============================================================================

 Crypto Exchange rates update

 ==============================================================================
 */
export const RatesInterval = {
  timeoutId: null,
  milliseconds: 60000, // 1 minute
  start() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
    return RatesService.getRates(window.site_currency).then((rates) => {
      this._triggerRefreshAndSetRates(rates);
    });
  },
  _triggerRefreshAndSetRates(rates) {
    this.timeoutId = window.setTimeout(this._refresh.bind(this), this.milliseconds);
    if (rates === '') return;
    CryptoRatesData.setRates(rates);
  },
  _refresh() {
    RatesService.getRates(window.site_currency).then((rates) => {
      this._triggerRefreshAndSetRates(rates);
    });
  },
};


function CryptoRates() {
  this.cryptoCurrencies = [
    'AUR',
    'BTC',
    'BCH',
    'BLK',
    'LTC',
    'TIT',
    'XRP',
    'XPM',
    'POT',
    'EMC',
    'PPC',
    'NXT',
    'NMC',
    'XMR',
    'MZC',
    'MSC',
    'GRC',
    'ETH',
    'XDN',
    'XDG',
    'DCR',
  ];
  this.prices = [];
  
  this.setRates = function (prices) {
    this.prices = prices;
    
    eventBus.emit(exchangeFormEventNames.FORM_CRYPTO_RATES_UPDATED, {})
  };
  
  this.getFiatPricesForCryptoCode = (cryptoCode) => {
    return this.prices[cryptoCode];
  };
  
  this.hasPriceForCryptoCode = (cryptoCode) => {
    return cryptoCode in this.prices
  };
}

const CryptoRatesUpdater = {
  init({ detail = {} }) {
    if ('currency' in detail) {
      CryptoRatesUpdater.setCurrency(detail.currency);
    }

    CryptoRatesUpdater.updateRates();
  },
  selectors: {
    cryptoCurrenciesList: '[data-selector="rbcc-scroll-item"]',
    cryptoRate: '[data-selector="crypto-rate"]',
    cryptoRateChange: '[data-selector="crypto-rate-change"]',
  },
  currency: window.site_currency || 'USD',

  setCryptoRates(rates) {
    document
      .querySelectorAll(this.selectors.cryptoCurrenciesList)
      .forEach((element) => {
        const $cryptoRate = element.querySelector(this.selectors.cryptoRate);
        const { coin: coinName } = $cryptoRate.dataset;

        if (!(coinName in rates)) {
          ElementToggle.hide(element);

          return;
        }
        ElementToggle.show(element);
        const dataKey = `value-${this.currency}`;
        const previousValue = $cryptoRate.dataset[dataKey];
        const cryptoRate = parseFloat(rates[coinName]);
        const rateRatio = (1 - previousValue / cryptoRate) * 100;
        const rateRatioRounded = Math.ceil(rateRatio * 100) / 100;

        if (!isNaN(rateRatioRounded) && Math.abs(rateRatioRounded) > 0) {
          element.querySelector(this.selectors.cryptoRateChange).innerHTML =
            (rateRatioRounded > 0 ? '+' : '') + rateRatioRounded + '%';
        }
        $cryptoRate.dataset[dataKey] = cryptoRate;
        $cryptoRate.innerHTML = this.format(cryptoRate, this.currency);
      });

    eventBus.emit(HOME_PAGE_RATES_CAROUSEL_UPDATE);
  },

  format(rate, currency) {
    const options = { style: 'currency', currency: currency };

    if (1 > rate) {
      options.minimumFractionDigits = 5;
    }

    return Number(rate).toLocaleString(undefined, options);
  },

  remapRatesObject(rates) {
    const cryptos = {};
    Object.keys(rates).forEach((crypto) => {
      const fiats = rates[crypto];
      if (!(this.currency in fiats)) {
        return;
      }

      cryptos[crypto] = fiats[this.currency];
    });

    return cryptos;
  },

  updateRates() {
    const { prices } = CryptoRatesData || {};
    if (!prices) {
      throw new Error('No crypto rates are loaded.');
    }

    this.setCryptoRates(this.remapRatesObject(prices));
  },

  setCurrency(currency) {
    this.currency = currency || this.currency;
  },
};

window.addEventListener('currency-updated', CryptoRatesUpdater.init);
